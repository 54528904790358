/* -- container -- */
.modal,
.modal-mask {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.modal {
    position: fixed;
    z-index: 900;
}

/* -- mask -- */
.modal-mask {
    position: absolute;
    background: rgba(0, 0, 0, 0.64);
}

/* -- dialog -- */
.modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 101;
    padding: 15px;
    border-radius: 3px;
}

.modal-dialog:focus {
    outline: none;
}

/* -- fade -- */
@-webkit-keyframes modal-fade-enter {
    from {
        opacity: 0;
    }
}

@keyframes modal-fade-enter {
    from {
        opacity: 0;
    }
}

.modal-fade-enter {
    -webkit-animation: modal-fade-enter both ease-in;
    animation: modal-fade-enter both ease-in;
}

@-webkit-keyframes modal-fade-leave {
    to {
        opacity: 0;
    }
}

@keyframes modal-fade-leave {
    to {
        opacity: 0;
    }
}

.modal-fade-leave {
    -webkit-animation: modal-fade-leave both ease-out;
    animation: modal-fade-leave both ease-out;
}

/* -- zoom -- */
@-webkit-keyframes modal-zoom-enter {
    from {
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }
}

@keyframes modal-zoom-enter {
    from {
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }
}

.modal-zoom-enter {
    -webkit-animation: modal-zoom-enter both cubic-bezier(0.4, 0, 0, 1.5);
    animation: modal-zoom-enter both cubic-bezier(0.4, 0, 0, 1.5);
}

@-webkit-keyframes modal-zoom-leave {
    to {
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }
}

@keyframes modal-zoom-leave {
    to {
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }
}

.modal-zoom-leave {
    -webkit-animation: modal-zoom-leave both;
    animation: modal-zoom-leave both;
}